import { get, post } from '@va/http-client';
import { storageItems } from '@va/standalone/shared/constants';
import { LocalStorage } from '@va/util/helpers';
import { useAsyncFunction } from '@va/util/hooks';
import { useCallback } from 'react';

export const createUser = (data) => {
  const url = `/users`;

  return post(url, {}, data);
};

export const activateUser = (data) => {
  const url = `/users/confirm`;

  return post(url, {}, data);
};

export const login = (data) => {
  const url = `/token`;

  return post(url, {}, data);
};

export const generateImpersonateToken = (data) => {
  const url = `/impersonate/token`;

  return post(url, {}, data);
};

export const refreshToken = (data) => {
  const url = `/token-refresh`;

  return post(url, {}, data);
};

export const refreshImpersonateToken = (data) => {
  const url = `/impersonate/token-refresh`;

  return post(url, {}, data);
};

export const forgotPassword = (data) => {
  const url = `/users/forgot-password`;

  return post(url, {}, data);
};

export const resetPassword = (data) => {
  const url = `/users/reset-password`;

  return post(url, {}, data);
};

export const registerContributor = (data) => {
  const url = `/users/contributors`;
  return post(url, {}, data);
};

export const addVoucher = (data, userId) => {
  const id = userId || LocalStorage.getItem(storageItems.userId);
  const url = `/sumo/users/${id}/vouchers`;
  return post(url, {}, data);
};

export const getGoogleImporterAuthUrl = (userId) => {
  const id = userId || LocalStorage.getItem(storageItems.userId);
  const url = `/google/${id}/auth/redirect-url`;
  return get(url, {});
};

export const getGoogleImporterViews = (queryParams) => {
  const url = `/google/auth/token${queryParams}`;
  return get(url, {});
};

export const startGoogleImporter = (websiteId, viewId) => {
  const url = `/google/${websiteId}/${viewId}/importer/start`;
  return post(url, {});
};

export const getGoogleImporterProgress = (websiteId) => {
  const url = `/google/${websiteId}/importer/progress`;
  return get(url, {});
};

export const useLogin = (autoResetSuccessState) => {
  const asyncFn = useCallback((email, password) => {
    return post('/token', {}, { email, password });
  }, []);
  return useAsyncFunction(asyncFn, { autoResetSuccessState });
};

export const useRegisterUser = () => {
  const register = useCallback(({ email, password, privacyTermsAccepted, marketingVoucher, platform, locale }) => {
    return post(
      '/users',
      {},
      {
        email: email,
        password: password,
        privacyTermsAccepted: privacyTermsAccepted,
        dpaAccepted: privacyTermsAccepted,
        businessConditionAccepted: privacyTermsAccepted,
        marketingVoucher: marketingVoucher,
        platform: platform,
        locale: locale,
        platform: platform,
      },
    );
  }, []);

  return useAsyncFunction(register);
};
